/* ---------------------- */
/* Fonts                  */
/* ---------------------- */

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap");

/* ---------------------- */
/* Custom properties      */
/* ---------------------- */

:root {
  --clr-light-heading: #f5f5f5;
  --clr-light-text: #dcdcdc;

  --clr-background-darker: #161619;
  --clr-background-dark: #1a1b21;

  --clr-social-icons: #918e9b;
  --clr-accent: #f3bf99;
  --clr-linkedin: #5093e2;

  --ff-main: "Inter", sans-serif;
}

/* ---------------------- */
/* Reset                  */
/* ---------------------- */

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #23252c;
  display: flex;
  align-items: center;
  justify-content: center;
}

img {
  display: block;
  width: 100%;
}

/* remove animations for people who've turned them off */
@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

/* ---------------------- */
/* Typography             */
/* ---------------------- */

html {
  font-family: var(--ff-main);
}

p {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 150%;
}

/* ---------------------- */
/* Buttons                */
/* ---------------------- */

.info--buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 2em;
}

.button {
  min-width: 115px;
  font-size: 1rem;
  font-weight: 500;
  padding: 0.5em 0.75em;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  cursor: pointer;
}

.button:hover {
  filter: brightness(85%);
}

.button--email {
  border: 1px solid var(--clr-light-heading);
}

.button--linkedin {
  color: var(--clr-light-heading);
  background-color: var(--clr-linkedin);
  border: 1px solid var(--clr-linkedin);
}

/* ---------------------- */
/* Components             */
/* ---------------------- */

#root {
  max-width: 22em;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07),
    0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07),
    0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);
  border-radius: 100px;
  margin: 3em;
}

.card {
  color: var(--clr-light-text);
  background-color: var(--clr-background-dark);
  border-radius: 10px;
}

.container {
  padding: 0 2em 2em 2em;
}

/* INFO */

.info {
  text-align: center;
}

.info--photo {
  border-radius: 10px 10px 0 0;
}

.info--fullname {
  margin-top: 1em;
  padding: 0 2em;
}

.info--role {
  font-weight: 400;
  color: var(--clr-accent);
  margin-top: 0;
}

.info--website {
  padding: 1em;
}
.info--websiteLink {
  text-decoration: none;
  color: var(--clr-light-text);
}

.info--icon {
  margin-right: 10px;
}

/* ABOUT */

.about {
  margin-top: 2em;
}
.about--title {
  margin-bottom: 0.5em;
}

/* INTERESTS */
.interests {
  margin-top: 2em;
}
.interests--title {
  margin-bottom: 0.5em;
}

/* FOOTER */

.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 1em 2em;
  background-color: var(--clr-background-darker);
  border-radius: 0px 0px 10px 10px;
}

.footer--icon {
  height: 25px;
  color: var(--clr-social-icons);
}

.footer--icon:hover {
  filter: brightness(120%);
}
